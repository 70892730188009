import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxTypes } from '../../../../type_models/ReduxTypes';
import { changeStateValue } from '../../../../redux/MainReducer';
import { ArrowDownSvg, InfoCircleSvg } from '../../../../assets';
import style from './style.module.css'

function Companies() {
  const [dropVisible, setDropVisible] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<string>('Saat Store'); // Seçilən elementi saxlamaq üçün state
  const modalInfoVisible = useSelector((state: ReduxTypes) => state.Data.modalInfoVisible);
  const dispatch = useDispatch<any>();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleSelect = (store: string) => {
    setSelectedStore(store); 
    setDropVisible(false); 
  };

  return (
    <div className={style.dropdown} ref={dropdownRef}>
      <div
        className={`${style['dropdown-head']} ${dropVisible ? style['dropdown-head_hover'] : ''}`}
        onClick={() => setDropVisible(!dropVisible)}
      >
        <div className={style["dropdown-left"]}>
          <button
            className={style["info-btn"]}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(changeStateValue({ name: 'modalInfoVisible', value: !modalInfoVisible }));
            }}
          >
            <InfoCircleSvg />
          </button>
          <div className={style["dropdown-left"]}>
            <span>{selectedStore}</span> 
            <div className={style["dropdown-svg"]}>
              <ArrowDownSvg />
            </div>
          </div>
        </div>
      </div>
      <ul className={`${style['dropdown-content']} ${dropVisible ? style['visible'] : ''}`}>
        {['Saat Store', 'SAF MMC', 'Store', 'Store'].map((store, index) => (
          <li key={index} onClick={() => handleSelect(store)}> 
            <span>{store}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Companies;
