import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { moneyFormat } from "../../actions/OtherFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getCashTotal } from "../../actions/MainAction";
import { CurrencyAZNSvg, CurrencyEurSvg, CurrencyUsdSvg } from "../../assets";
import { ReduxTypes } from "../../type_models/ReduxTypes";

const currencyIcon = (currencyID: string) => {
  if (currencyID === 'bc1e189a-6b2d-11ed-a3d9-b47af12e823d') {
    return <CurrencyUsdSvg />
  } else if (currencyID === 'bc1e1899-6b2d-11ed-a3d9-b47af12e823d') {
    return <CurrencyEurSvg />
  } else {
    return <CurrencyAZNSvg />
  }
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, value, tooltipValue, selectedCurrencyID } = payload[0].payload;

    return (
      <div
        style={{
          backgroundColor: "#EDEFF1",
          borderRadius: "12px",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: "5px", fontWeight: "bold" }}>{name}</div>
        <div>{tooltipValue.toFixed(2)}  {currencyIcon(selectedCurrencyID)}</div>
      </div>
    );
  }
  return null;
};

const DonutChartExample = ({ data = [] }: { data?: any }) => {
  const [fontSize, setFontSize] = useState("16px");
  const dispatch = useDispatch<any>();
  const dayFilter = useSelector((state: ReduxTypes) => state.Data.dayFilter);

  const getCashTotalById = (id: string) => {
    const paramsTotal = { "type": "Cash", "period_type": dayFilter, "number_of_days": 60, "company_id": "", "cash_id": id, "currencyID": selectedCurrencyID }
    dispatch(getCashTotal(paramsTotal))
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize("12px");
      } else if (window.innerWidth <= 800) {
        setFontSize("14px");
      } else {
        setFontSize("16px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  // Ensure minimum slice size
  const minSliceSize = 5;
  const totalValue = data.reduce((acc: any, item: any) => acc + item.value, 0);

  const selectedCurrencyID = useSelector((state: ReduxTypes) => state.Data.selectedCurrencyID);

  const adjustedData = data.map((entry: any) => {
    const sliceWidth = (entry.value / totalValue) * 360; 
    const minAngle = (minSliceSize / 200) * 360;
    return sliceWidth < minAngle
      ? { ...entry, value: (minAngle / 360) * totalValue, tooltipValue: entry?.value }
      : { ...entry, tooltipValue: entry?.value };
  });
  // console.log(adjustedData)
  // console.log(data)

  return (
    <div
      className="chart-card-circle chart-container"
      style={{ backgroundColor: "white", position: "relative" }}
    >
      <div
        style={{
          textAlign: "center",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          alignItems: "center",
        }}
      >
        <PieChart
          width={window.innerWidth <= 600 ? 200 : 350}
          height={window.innerWidth <= 600 ? 200 : 350}
        >
          <Pie
            data={adjustedData}
            dataKey="value"
            nameKey="name"
            innerRadius={window.innerWidth <= 600 ? 70 : 115}
            outerRadius={window.innerWidth <= 600 ? 90 : 140}
            fill="#8884d8"
            paddingAngle={1}
            style={{
              outline: "none",
            }}
          >
            {adjustedData.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={entry.color}
                onClick={() => getCashTotalById(entry.cashID)}

              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
        <div
          className="pieChartText"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
          }}
        >
          <h3>Total Value</h3>
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 6
            }}
          >{currencyIcon(selectedCurrencyID)}   {moneyFormat(totalValue)}</p>
        </div>
      </div>

      <div style={{ marginTop: 17 }}>
        {/* Scrollable legend for remaining items */}
        <div
          style={{
            maxHeight: window.innerWidth <= 600 ? "150px" : "172px",
            overflowY: "auto",
            padding: "8px 6px",
          }}
          className="donut-scroll"
        >
          {data.sort((a: any, b: any) => b.value - a.value).map((entry: any, index: number) => (
            <div
              key={index}
              className="donut-line"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 12,
                padding: 8,
                fontSize: fontSize,
                cursor: 'pointer'
              }}
              onClick={() => getCashTotalById(entry.cashID)}
            >
              <div
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: entry.color,
                  marginRight: 10,
                  borderRadius: "2px",
                }}
              ></div>
              <span style={{ fontWeight: 600 }}>{entry.name}</span>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 6,
                  marginLeft: 'auto'
                }}
              >
                {entry.value.toFixed(2)} {currencyIcon(selectedCurrencyID)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonutChartExample;
