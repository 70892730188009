import React, { useState, useEffect, useRef } from 'react';
import { ArrowDownSvg, CurrencyAZNSvg, CurrencyEurSvg, CurrencyUsdSvg } from '../../../../assets';
import { useDispatch } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import style from './style.module.css'

function Exchange() {
  const [dropVisible, setDropVisible] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<{ value: string; icon: JSX.Element }>({
    value: '1.00',
    icon: <CurrencyAZNSvg />,
  });
  const dispatch = useDispatch<any>();

  const data = [
    // { value: '1.00', icon: <CurrencyAZNSvg /> ,id: 'bc1e1898-6b2d-11ed-a3d9-b47af12e823d'},
    { value: '1.00', icon: <CurrencyAZNSvg /> ,id: ''},
    { value: '1.78', icon: <CurrencyUsdSvg /> ,id: 'bc1e189a-6b2d-11ed-a3d9-b47af12e823d'},
    { value: '1.99', icon: <CurrencyEurSvg /> ,id: 'bc1e1899-6b2d-11ed-a3d9-b47af12e823d'},
  ];

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (item: { value: string; icon: JSX.Element ;id:string}) => {
    setSelectedCurrency(item);
    // console.log(`valyuta: ${item.value}`);
    setDropVisible(false);
    dispatch(changeStateValue({name:'selectedCurrencyID',value: item.id}));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={style.dropdown} ref={dropdownRef}>
      <button
        onClick={() => setDropVisible(!dropVisible)}
        className={`${style['dropdown-head']} ${dropVisible ? style['dropdown-head_hover'] : ''}`}
      >
        <div className={style["dropdown-left"]}>
          <div className={style.currency}>
            {React.cloneElement(selectedCurrency.icon, {
              stroke: '#fff',
              fill: '#fff',
            })}
          </div>
          <span>{selectedCurrency.value}</span>
        </div>
        <div className={style["dropdown-svg"]}>
          <ArrowDownSvg />
        </div>
      </button>
        <ul className={`${style['dropdown-content-currency']} ${dropVisible ? style['visible'] : ''}`}>
          {data.map((item, index) => (
            <li key={index} onClick={() => handleSelect(item)}>
              <div className={style.currency}>
                {React.cloneElement(item.icon, {
                  stroke: '#fff',
                  fill: '#fff',
                })}
              </div>
              <span>{item.value}</span>
            </li>
          ))}
        </ul>
    </div>
  );
}

export default Exchange;
