import React from 'react'

function StatusUpSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M9.173 24.2v-2.76M16 24.2v-5.52M22.827 24.2v-8.293M22.827 7.8l-.614.72a25.176 25.176 0 0 1-13.04 8.053"
    />
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.92 7.8h3.907v3.893"
    />
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 29.333h8c6.667 0 9.333-2.666 9.333-9.333v-8c0-6.667-2.666-9.333-9.333-9.333h-8c-6.667 0-9.333 2.666-9.333 9.333v8c0 6.667 2.666 9.333 9.333 9.333Z"
    />
  </svg>
  )
}

export default StatusUpSvg
