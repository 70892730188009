import SingleChartComponent from "../../chartSetting/SingleChartComponent"
import style from './style.module.css';

function SingleGraficView() {
  return (
    <div className={style['one-charts']}>
        <SingleChartComponent />
    </div>
  )
}

export default SingleGraficView
