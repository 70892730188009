import { TableProps } from "../../../type_models/PropsTypes";
import style from './style.module.css';

function Table({ length }: TableProps) {
  const data  = [
    { name: "Əsas kassa", value: 158400, color:"#0088FE" },
    { name: "İkinci kassa", value: 80300, color: "#00C49F"},
    { name: "Nərimanov filialı", value: 12300, color: "#FFBB28"},
    { name: "Baş ofis", value: 280200, color: "#FF8042"},
    { name: "Qalıq kassası", value: 5100, color: "#A020F0"},
  ];
  const data1 = [
    { date: "2024-12-01", balance: 705520 },
    { date: "2024-12-02", balance: 805500 },
    { date: "2024-12-03", balance: 846520 },
    { date: "2024-12-04", balance: 751500 },
    { date: "2024-12-05", balance: 564520 },
    { date: "2024-12-06", balance: 185500 },
    { date: "2024-12-07", balance: 196520 },
    { date: "2024-12-08", balance: 750500 },
    { date: "2024-12-09", balance: 400520 },
    { date: "2024-12-10", balance: 420500 },
    { date: "2024-12-11", balance: 650520 },
    { date: "2024-12-12", balance: 460500 },
    { date: "2024-12-13", balance: 847520 },
    { date: "2024-12-14", balance: 396500 },
    { date: "2024-12-15", balance: 409520 },
    { date: "2024-12-16", balance: 198500 },
    { date: "2024-12-17", balance: 348520 },
    { date: "2024-12-18", balance: 786500 },
    { date: "2024-12-19", balance: 493520 },
    { date: "2024-12-20", balance: 548500 },
    { date: "2024-12-21", balance: 499520 },
    { date: "2024-12-22", balance: 654500 },
    { date: "2024-12-23", balance: 745520 },
    { date: "2024-12-24", balance: 718500 },
    { date: "2024-12-25", balance: 862520 },
    { date: "2024-12-26", balance: 456500 },
    { date: "2024-12-27", balance: 354520 },
    { date: "2024-12-28", balance: 398500 },
    { date: "2024-12-29", balance: 420520 },
    { date: "2024-12-30", balance: 536300 }
  ]
  return (  
    <div className={style["table-card"]}>
      <div className={style["table-container"]}>
        {
          length === 5 ? <table>
          <thead>
            <tr>
              <th>№</th>
              <th>Kassa adı</th>
              <th>Məbləğ</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((d,index)=>{
                return <tr key={index} >
                <td>{index + 1}</td>
                <td>{d.name}</td>
                <td>{d.value}</td>
              </tr>
              })
            }
          </tbody>
        </table> : <table>
          <thead>
            <tr>
              <th>№</th>
              <th>Tarix</th>
              <th>Məbləğ</th>
            </tr>
          </thead>
          <tbody>
            {
              data1.map((d,index)=>{
                return <tr key={index} >
                <td>{index + 1}</td>
                <td>{d.date}</td>
                <td>{d.balance}</td>
              </tr>
              })
            }
          </tbody>
        </table>
        }
        
      </div>
    </div>
  );
}

export default Table;
