import { useDispatch, useSelector } from 'react-redux';
import { ArshiveBookSvg, Bank1Svg, BriefCaseSvg, CallSvg, CloseSvg, EmailSvg, UserSvg, VOENSvg, WhatsappSvg } from '../../../assets';
import { ReduxTypes } from '../../../type_models/ReduxTypes';
import { changeStateValue } from '../../../redux/MainReducer';
import style from './style.module.css';

function ModalInfo() {
  const modalInfoVisible = useSelector((state: ReduxTypes) => state.Data.modalInfoVisible)
  const dispatch = useDispatch<any>();
  const closeModal = () => {
    if (modalInfoVisible) {
      dispatch(changeStateValue({ name: "modalInfoVisible", value: false }));
    } else {
      dispatch(changeStateValue({ name: "modalInfoVisible", value: true }));
    }
  };
  return (
    <div className={style[modalInfoVisible ? 'background' : '']}>
      <div
        className={style['modal-info']}
        style={{ bottom: modalInfoVisible ? "0" : '-100%' }}
      >
        <div className={style["modal-info-top"]}>
          <h2>Haqqinda</h2>
          <div className={style["close-modal"]}>
            <button className={style.whatsapp}>
              <WhatsappSvg />
            </button>
            <button className={style.close} onClick={() => dispatch(changeStateValue({ name: "modalInfoVisible", value: false }))}>
              <CloseSvg />
            </button>
          </div>
        </div>
        <div className={style["modal-info-main"]}>
          <ul>
            <li>
              <div className={style.left}>
                <VOENSvg />
                <h6>Vöen:</h6>
              </div>
              <div className={style.right}>
                <h6>2132569659663</h6>
              </div>
            </li>
            <li>
              <div className={style.left}>
                <Bank1Svg />
                <h6>Əsas bank hesabı:</h6>
              </div>
              <div className={style.right}>
                <h6>Kapital Bank:</h6>
                <span>2132569659663</span>
              </div>
            </li>
            <li>
              <div className={style.left}>
                <Bank1Svg />
                <h6>Hesablaşma hesabı:</h6>
              </div>
              <div className={style.right}>
                <h6>Paşa Bank:</h6>
                <span>2132569659663</span>
              </div>
            </li>
            <li>
              <div className={style.left}>
                <ArshiveBookSvg />
                <h6>Hüquqi ünvan:</h6>
              </div>
              <div className={style.right}>
                <h6>Bakı.N.Nərimano Şərifzadə N15</h6>
              </div>
            </li>
            <li>
              <div className={style.left}>
                <EmailSvg />
                <h6>Email ünvanı:</h6>
              </div>
              <div className={style.right}>
                <h6>siesco@gmail.com</h6>
              </div>
            </li>
            <li>
              <div className={style.left}>
                <CallSvg />
                <h6>Əlaqə nömrəsi:</h6>
              </div>
              <div className={style.right}>
                <h6>050 632 52 63</h6>
              </div>
            </li>
            <li>
              <div className={style.left}>
                <UserSvg />
                <h6>Direktorun adı, soyadı:</h6>
              </div>
              <div className={style.right}>
                <h6>Şahin Fərəcov</h6>
              </div>
            </li>
            <li>
              <div className={style.left}>
                <BriefCaseSvg />
                <h6>Rəhbərin vəzifəsi:</h6>
              </div>
              <div className={style.right}>
                <h6>Direktor</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ModalInfo;
