import Table from '../Table';
import style from './style.module.css';

function TableView() {
  return (
      <div className={style['table-view']}>
        <Table length={5}/>
        <Table length={17}/>
      </div>
  )
}

export default TableView
