import React from 'react'

function BankSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m16.493 2.867 12 4.8c.467.186.84.746.84 1.24v4.426c0 .734-.6 1.334-1.333 1.334H4c-.733 0-1.333-.6-1.333-1.334V8.907c0-.494.373-1.054.84-1.24l12-4.8c.266-.107.72-.107.986 0ZM29.333 29.333H2.667v-4C2.667 24.6 3.267 24 4 24h24c.733 0 1.333.6 1.333 1.333v4ZM5.333 24v-9.333M10.667 24v-9.333M16 24v-9.333M21.333 24v-9.333M26.667 24v-9.333M1.333 29.333h29.334"
    />
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M16 11.333a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
  )
}

export default BankSvg
