import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import style from './style.module.css'

function Months() {
  const [activeBtn, setActiveBtn] = useState<number>(2);
  const dispatch = useDispatch<any>()
  const buttons = [
    { id: 1, label: 'Son 30 gün' },
    { id: 2, label: 'Cari ay' },
    { id: 3, label: 'Keçən ay' },
  ];

  function getLastMonth() {
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0); 
    return { start: lastMonth, end: lastMonthEnd };
 }

 function getCurrentMonth() {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1); 
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return { start, end };
  }
  function getLast30Days() {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - 30); 
    return { start, end: now };
  }

  useEffect(()=>{
    dispatch(changeStateValue({name:"monthFilter", value:{
      start: getLast30Days()?.start,
      end: getLast30Days()?.end
    }}))
  },[])

  const handleClick = (id: number) => {
     dispatch(changeStateValue({name:"dayFilter", value:id}))
     if(id ==1){
      dispatch(changeStateValue({name:"monthFilter", value:{
        start: getLast30Days()?.start,
        end: getLast30Days()?.end
      }}))
     }else if(id == 2){
      dispatch(changeStateValue({name:"monthFilter", value:{
        start: getCurrentMonth()?.start,
        end: getCurrentMonth()?.end
      }}))
     }else{
      dispatch(changeStateValue({name:"monthFilter", value:{
        start: getLastMonth()?.start,
        end: getLastMonth()?.end
      }}))
     }
    setActiveBtn(id);
  };
  
  return (
    <div className={style.months}>
      <ul className={style['months-list']}>
        {buttons.map((button) => (
          <li
            key={button.id}
            onClick={() => handleClick(button.id)}
            className={activeBtn === button.id ? style['active-list'] : ''}
          >
            <span>{button.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Months;
