
function InfoCircleSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
    >
        <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15 27.5c6.875 0 12.5-5.625 12.5-12.5S21.875 2.5 15 2.5 2.5 8.125 2.5 15 8.125 27.5 15 27.5Z"
        />
        <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15 10v6.25"
        />
        <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14.993 20h.011"
        />
    </svg>
  )
}

export default InfoCircleSvg
