import React from 'react'

function WalletSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
    >
        <path
        
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M29.333 16v6.667c0 4-2.666 6.666-6.666 6.666H9.333c-4 0-6.666-2.666-6.666-6.666V16c0-3.627 2.186-6.16 5.586-6.587.347-.053.707-.08 1.08-.08h13.334c.346 0 .68.014 1 .067 3.44.4 5.666 2.947 5.666 6.6Z"
        />
        <path
        
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M23.669 9.4a6.098 6.098 0 0 0-1-.067H9.335c-.373 0-.733.027-1.08.08.187-.373.454-.72.774-1.04l4.333-4.346a4.7 4.7 0 0 1 6.613 0l2.334 2.36a4.5 4.5 0 0 1 1.36 3.013ZM29.333 16.667h-4a2.675 2.675 0 0 0-2.666 2.666c0 1.467 1.2 2.667 2.666 2.667h4"
        />
    </svg>
  )
}

export default WalletSvg
