import React, { useEffect } from 'react'
import { ReduxTypes } from '../type_models/ReduxTypes'
import { useDispatch, useSelector } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer'
import { getCash, getCashTotal } from '../actions/MainAction'
import MainTop from '../components/layouts/MainTop'
import TableView from '../components/layouts/TableView'
import GraphicView from '../components/layouts/GraphicView'
import moment from 'moment'

function CashPage() {
  const tableVisible = useSelector((state: ReduxTypes) => state.Data.tableVisible);
  const cashData = useSelector((state: ReduxTypes) => state.Data.cashData);
  const cashTotalData = useSelector((state: ReduxTypes) => state.Data.cashTotalData);
  const monthFilter = useSelector((state: ReduxTypes) => state.Data.monthFilter);
  const selectedCurrencyID = useSelector((state: ReduxTypes) => state.Data.selectedCurrencyID);
  const dayFilter = useSelector((state: ReduxTypes) => state.Data.dayFilter);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const params = { "type": "Cash", "period_type": dayFilter, "number_of_days": 60, "company_id": "", "cash_id": "", "currencyID": selectedCurrencyID }
    // dispatch(getCash(params))
    const paramsTotal = { "type": "Cash", "period_type": dayFilter, "number_of_days": 60, "company_id": "", "cash_id": "", "currencyID": selectedCurrencyID }
    // dispatch(getCashTotal(paramsTotal))
    return () => {
      // dispatch(changeStateValue({ name: "tableVisible", value: false }));
    };
  }, [selectedCurrencyID,dayFilter]);

  const getGroupedValue = (data: any) => {
    let result = data.reduce(function (r: any, a: any) {
      r[a.cashID] = r[a.cashID] || [];
      r[a.cashID].push(a);
      return r;
    }, {});
    return [...Object.values(result)];
  }

  const sumPrice = (data: any) => {
    let sum: number = 0;
    for (const item of data) {
      if (selectedCurrencyID == '') {
        sum += item?.sum;
      } else {
        sum += item?.sum_currency
      }
    }
    return sum;
  }
  function getDaysBetweenDates(startDate: any, endDate: any) {
    const daysArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      daysArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return daysArray;
  }

  const getDataByAllDays = (data: any) => {
    let results = [];
    console.log("data", data)

    
    for (const date of getDaysBetweenDates(new Date(monthFilter?.start), new Date(moment(monthFilter?.end).format("YYYY-MM-DD 23:59:59")))) {
      const total = data.filter((x: any) => moment(x.date).format("DD.MM.YYYY") == moment(date)?.format("DD.MM.YYYY"));
      results.push({
        date: moment(date)?.format("DD.MM.YYYY"),
        balance: sumPrice(total)
      })
    }
    return results
  }
  const getDataFormat = (data: any) => {
    const colors = ["#F5C242", "#EA334B", "#3070F5", "#AE59DC", "#F2A73B",  "#5B61D6", "#57BEB5"]
    let arr: any = [];

    getGroupedValue(data).forEach((item: any, i: number) => {
      arr.push({
        name: item[0]?.cash_register_name,
        cashID: item[0]?.cashID,
        currencyID: item[0]?.currencyID,
        value: sumPrice(item),
        color: colors[i]
      })
    })

    return arr;
  }

  const getGroupedTotalValue = (data: any) => {
    let result = data.reduce(function (r: any, a: any) {
      r[a.date] = r[a.date] || [];
      r[a.date].push(a);
      return r;
    }, {});
    return [...Object.values(result)];
  }


  return (
    <>
      <MainTop />
      <>
        {
          tableVisible ? <TableView /> : <GraphicView totalData={getDataFormat(cashData)} cashTotal={getDataByAllDays(cashTotalData)} />
        }
      </>
    </>
  )
}

export default CashPage
