import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";


export const getCash = (params) => async dispatch => {
    return await axios.post(`${MAIN_API}Cash`,JSON.stringify(params),
        {
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': 'Basic SFRUUDpTYWxpYW5Gb3JldmVy'
              },
        }
    )
    .then(resp => {
        dispatch(changeStateValue({name:"cashData", value: resp?.data?.response?.data }))
    }).catch(err=>{
        console.log(err.response)
    })
}


export const getCashTotal = (params) => async dispatch => {
    //bu sag terefdi?yes
   
    return await axios.post(`${MAIN_API}CashTotal`,JSON.stringify(params),
        {
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': 'Basic SFRUUDpTYWxpYW5Gb3JldmVy'
              },
        }
    )
    .then(resp => {

        // console.log('api resop', resp?.data)
        dispatch(changeStateValue({name:"cashTotalData", value: resp?.data?.response?.data }))
    }).catch(err=>{
        console.log(err.response)
    })
}