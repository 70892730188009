import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend
} from 'recharts';
import { CurrencyAZNSvg, CurrencyEurSvg, CurrencyUsdSvg } from '../../assets';
import { useSelector } from 'react-redux';
import { ReduxTypes } from '../../type_models/ReduxTypes';

const currencyIcon = (currencyID: string) => {
  if (currencyID === 'bc1e189a-6b2d-11ed-a3d9-b47af12e823d') {
    return <CurrencyUsdSvg />
  } else if (currencyID === 'bc1e1899-6b2d-11ed-a3d9-b47af12e823d') {
    return <CurrencyEurSvg />
  } else {
    return <CurrencyAZNSvg />
  }
}
const CustomTooltip = ({ active, payload }: any) => {
  const selectedCurrencyID = useSelector((state: ReduxTypes) => state.Data.selectedCurrencyID);

  if (active && payload && payload.length) {
    const { date, balance } = payload[0].payload;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            background: '#EDEFF1',
            borderRadius: '12px 12px 0 0 ',
            padding: '8px',
          }}
        >
          <span style={{ fontWeight: 400 }}>{date}</span>
        </div>
        <div
          style={{
            background: '#FFF',
            borderRadius: '0 0 12px 12px',
            padding: '8px',
            boxShadow: '0px 4px 10px 0px rgba(14, 73, 113, 0.10)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 6
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#249BF4',
              borderRadius: '30px',
              marginRight: '8px',
            }}
          ></span>
          <strong>Balance:</strong> {balance} {currencyIcon(selectedCurrencyID)}
        </div>
      </div>
    );
  }

  return null;
};

const SingleBarChartComponent = ({ total = [] }: { total?: any }) => {
  const [barSize, setBarSize] = useState(35);
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize(10);
        setBarSize(10);
      } else if (window.innerWidth <= 800) {
        setFontSize(14);
        setBarSize(20);
      } else {
        setFontSize(16);
        setBarSize(35);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // console.log("total", total)
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      style={{ backgroundColor: '#fff' }}
      className="chart-card diagram-card"
    >
      <BarChart data={total} >
        <CartesianGrid className="single-diagram" strokeDasharray="1" />
        <XAxis dataKey="date" tick={{ fill: '#A8A8A8' }} fontSize={fontSize} />
        <YAxis
          tick={{ fill: '#A8A8A8' }}
          fontSize={fontSize}
          width={window.innerWidth <= 600 ? 20 : 70}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend payload={[]} />
        <Bar dataKey="balance" fill="#249BF4" barSize={barSize} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SingleBarChartComponent;
