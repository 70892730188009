import React from 'react'

function WalletRemoveSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M29.333 16v6.667c0 4-2.666 6.666-6.666 6.666H10.173a4.792 4.792 0 0 0 1.054-1.253c.493-.8.773-1.747.773-2.747A5.332 5.332 0 0 0 6.667 20c-1.6 0-3.027.707-4 1.813V16c0-3.627 2.186-6.16 5.586-6.587.347-.053.707-.08 1.08-.08h13.334c.346 0 .68.014 1 .067 3.44.4 5.666 2.947 5.666 6.6Z"
    />
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M23.669 9.4a6.098 6.098 0 0 0-1-.067H9.335c-.373 0-.733.027-1.08.08.187-.373.454-.72.774-1.04l4.333-4.346a4.7 4.7 0 0 1 6.613 0l2.334 2.36a4.5 4.5 0 0 1 1.36 3.013ZM29.333 16.667h-4a2.675 2.675 0 0 0-2.666 2.666c0 1.467 1.2 2.667 2.666 2.667h4"
    />
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 25.333c0 1-.28 1.947-.773 2.747-.28.48-.64.907-1.054 1.253a5.207 5.207 0 0 1-3.506 1.334 5.293 5.293 0 0 1-4.56-2.587 5.228 5.228 0 0 1-.774-2.747c0-1.346.507-2.573 1.334-3.52a5.311 5.311 0 0 1 4-1.813A5.332 5.332 0 0 1 12 25.333ZM8.095 26.72l-2.813-2.813M8.053 23.947 5.24 26.76"
    />
  </svg>
  )
}

export default WalletRemoveSvg
