import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface DataPoint {
  name: string;
  value: number;
}

const data: DataPoint[] = [
  { name: 'Jan', value: 20 },
  { name: 'Feb', value: 10 },
  { name: 'Mar', value: 45 },
  { name: 'Apr', value: 20 },
  { name: 'May', value: 80 },
  { name: 'Jun', value: 100 },
];

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { date, balance } = payload[0].payload;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
        <div
          style={{
            background: '#fff',
            borderRadius: '12px 12px 0 0',
            padding: '7px',
            paddingBottom: '2px',
            color:'#0D3558',
            fontSize: '9px'
          }}
        >
          <strong>Date: </strong> {date}
        </div>
        <div
          style={{
            background: '#fff',
            borderRadius: '0 0 12px 12px',
            color:'#0D3558',
            padding: '7px',
            paddingTop: '1px',
            boxShadow: '0px 4px 10px 0px rgba(14, 73, 113, 0.10)',
            fontSize: '9px'
          }}
        >
          <strong>Balance: </strong> {balance} ₼
        </div>
      </div>
    );
  }

  return null;
};

type Props = {
  backgroundColor: string,
  title:string
}
function ChartComponent(props:Props) {
  const [fontSize, setFontSize] = useState('16px');
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize('16px');
      } else {
        setFontSize('16px');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div 
      className='chart-card chart-card-shadow' 
      style={{ backgroundColor: props.backgroundColor ,cursor: 'pointer'}} 
      onClick={()=>navigate('/cash')}
    >
      <div className='chart-info'>
        <h3 style={{fontSize:fontSize,marginBottom: '8px'}}>{props.title}</h3>
        <p>₼ 536,300.00</p>
      </div>
      <ResponsiveContainer width="100%" maxHeight={250}  height='80%'>
        <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} >
          <CartesianGrid stroke={window.innerWidth <= 600 ? 'none' : '#fff'} strokeDasharray="3 3" vertical={false} />
          <XAxis  
            dataKey="name"
            tick={false}
            height={window.innerWidth <= 600 ? 10 : 30 }
            stroke={window.innerWidth <= 600 ? 'none' : '#fff'}
          />  
          <YAxis
            tick={window.innerWidth <= 600 ? false :{ fill: '#ffffff' }}
            stroke="none"
            ticks={[0, 25, 50, 75, 100]}
            domain={[0, 120]}
            width={window.innerWidth <= 600 ? 0 : 35 }
            fontSize={fontSize}
          />
          <Line type="linear" dataKey="value" stroke="#ffffff"  strokeWidth={2} dot={false}/>
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartComponent;
