import style from './style.module.css';

function NoInformation() {
  return (
    <div className={style['no-information']}>
      <h2>Məlumat yoxdur</h2>
    </div>
  )
}

export default NoInformation
