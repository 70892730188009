import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../../assets/images/RedWhiteLogo.png'
import { HomeSvg, BankSvg, MoneySendSvg, MoneyReceivSvg, WalletRemoveSvg, WalletCheckSvg, CoinSvg, StatusUpSvg, TagSvg, ThumbtackSvg, WalletSvg, SettingSvg, ChartSvg, PercentageSvg } from '../../../assets'
import { ReduxTypes } from '../../../type_models/ReduxTypes'
import { changeStateValue } from '../../../redux/MainReducer'
import style from './style.module.css';

function Sidebar() {
  const minimize = useSelector((state: ReduxTypes) => state.Data.minimize)
  const dispatch = useDispatch<any>();

  function closeNav(e: any) {
    if (window.innerWidth <= 600) {

      if (typeof e.target.className == 'string' && e.target.className?.includes("mobile-background")) {
        dispatch(changeStateValue({ name: "navVisible", value: false }))
      }

    }
  }
  function closeNavIcon() {
    if (window.innerWidth > 600) {
      dispatch(changeStateValue({ name: "minimize", value: !minimize }))
    } else {
      dispatch(changeStateValue({ name: "navVisible", value: false }))
    }
  }
  return (
    <div onClick={(e: any) => closeNav(e)} className={window.innerWidth <= 600 ? style['mobile-background'] : ''}>
      <div className={`${style['nav-container']}  ${minimize ? style[ 'minimal-nav-container'] : ''}`}>
        <nav className={`${style.navbar} ${minimize ? style['minimal-nav'] : ''}`}>
          <div className={style["nav-logo"]}>
            <div className={style.logo}>
              <img src={logo} alt="Logo" />
            </div>
            <button onClick={closeNavIcon} style={{ backgroundColor: 'transparent' }}>
              <ThumbtackSvg />
            </button>
          </div>
          <ul className={style['nav-list']}>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/' >
                <HomeSvg />
                <span>Ana səhifə</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/cash' >
                <WalletSvg />
                <span>Kassa</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/noinfo' >
                <BankSvg />
                <span> Bank</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/bank' >
                <MoneySendSvg />
                <span>Debitor</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/debitor' >
                <MoneyReceivSvg />
                <span>Kreditor</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/kreditor' >
                <WalletRemoveSvg />
                <span>Pul silinmə</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/walletremove' >
                <WalletCheckSvg />
                <span>Pul mədaxil</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/walletadd' >
                <CoinSvg />
                <span>Anbar qalığı</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/warehouseresidue' >
                <StatusUpSvg />
                <span>İstehsal</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/production' >
                <TagSvg />
                <span>Alışlar</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/sales' >
                <SettingSvg />
                <span>Satışlar</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/taxes' >
                <PercentageSvg />
                <span>Vergilər</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => dispatch(changeStateValue({ name: "navVisible", value: false }))} to='/expenses' >
                <ChartSvg />
                <span>Xərclər</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
