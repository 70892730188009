import { useState, useRef, useEffect } from 'react';
import flagAZ from '../../../../assets/images/flagAz.png';
import flagEN from '../../../../assets/images/flagEn.png';
import flagRU from '../../../../assets/images/flagRu.png';
import { ArrowDownSvg, LogOutSvg, PersonIDCardSvg } from '../../../../assets';
import { LogOutProps } from '../../../../type_models/PropsTypes';
import style from './style.module.css'

function LogOut({ groupName }: LogOutProps) {
  const [dropVisible, setDropVisible] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const languages = [
    { code: 'AZ', name: 'AZ', flagSrc: flagAZ, altText: 'Azerbaijan flag' },
    { code: 'EN', name: 'EN', flagSrc: flagEN, altText: 'UK flag' },
    { code: 'RU', name: 'RU', flagSrc: flagRU, altText: 'Russian flag' },
  ];

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropVisible(false); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={style.dropdown} ref={dropdownRef}>
      <button
        onClick={() => setDropVisible(!dropVisible)}
        className={`${style['dropdown-head-end']} ${dropVisible ? style['dropdown-head'] : ''}`}
      >
        <PersonIDCardSvg />
        <span>{groupName}</span>
        <ArrowDownSvg />
      </button>
      <div className={`${style['dropdown-content-logout']} ${dropVisible ? style['visible'] : ''}`}>
        <div className={style.flags}>
          {languages.map((lang) => (
            <div key={lang.code} className={style.flag}>
              <div className={style["flag-circle"]}>
                <img src={lang.flagSrc} alt={lang.altText} />
              </div>
              <p>{lang.name}</p>
            </div>
          ))}
        </div>
        <button className={style["logout-btn"]}>
          <span>Çıxış</span>
          <LogOutSvg color="#0D3558" />
        </button>
      </div>
    </div>
  );
}

export default LogOut;
