import { useDispatch, useSelector } from 'react-redux'
import { ReduxTypes } from '../../type_models/ReduxTypes'
import { changeStateValue } from '../../redux/MainReducer'
import Months from './components/Months'
import Companies from './components/Companies'
import Exchange from './components/Exchange'
import LogOut from './components/LogOut'
import { MenuDarkSvg } from '../../assets'
import ScanSvg from '../../assets/svg/Scan'
import style from './style.module.css'

function Header() {
  const headerVisible = useSelector((state: ReduxTypes) => state.Data.headerVisible);
  const dispatch = useDispatch<any>();

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen(); 
    }
  };

  return (
    <div className={style.header}>
      <div className={style["header-top"]}>
        <button
          className={style['menu-tablet']}
          onClick={() => dispatch(changeStateValue({ name: "headerVisible", value: !headerVisible }))}
        >
          <MenuDarkSvg />
        </button>
        {headerVisible ? (
          <div className={style['tablet-dropdown']}>
            <Months />
            <div className={style['dropdown-t']}>
              <Companies />
              <Exchange />
            </div>
          </div>
        ) : (
          <div className={style['left-container']}>
            <Companies />
            <Months />
            <Exchange />
          </div>
        )}
        <div className={style['right-container']}>
          <button onClick={toggleFullscreen}>
            <ScanSvg />
          </button>
          <LogOut groupName="Xan Group"  />
        </div>
      </div>
    </div>
  );
}

export default Header;
